<template>
  <div>
    <div class="contact-us">
      <div class="contact-us__container">
        <div class="d-flex flex-column justify-content-center">
          <div v-if="loading" class="loader" id="loading-ai">
            <div class="loader-container">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="contact-us__content">
            <h2>Smart AI For Exterior Decoration</h2>
          </div>
          <div class="wrapper">
            <div class="box">
              <!-- <div class="js--image-preview"></div> -->
              <img
                alt="img"
                id="origin-image"
                class="js--image-preview"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
              />
              <div class="upload-options">
                <label>
                  <input type="file" class="image-upload" accept="image/*" />
                </label>
              </div>
            </div>
            <div class="box">
              <img
                alt="img"
                class="ai--image-preview"
                id="mask-image"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
              />
              <div class="ai-options">
                <label>
                  <input
                    type="button"
                    class="ai-upload"
                    onclick="changeColor"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex mt-3 justify-content-center" style="gap: 20px">
            <div class="color-picker d-flex flex-column">
              <input
                class="input-color"
                id="input-color-roof"
                name="Roof"
                type="color"
              />
              <span>Roof</span>
              <span id="value-color-roof"></span>
            </div>
            <div class="color-picker d-flex flex-column">
              <input
                class="input-color"
                id="input-color-wall"
                name="Wall"
                type="color"
              />
              <span>Wall</span>
              <span id="value-color-wall"></span>
            </div>
            <div class="color-picker d-flex flex-column">
              <input
                class="input-color"
                id="input-color-door"
                name="Door"
                type="color"
              />
              <span>Door</span>
              <span id="value-color-door"></span>
            </div>
          </div>
          <div
            class="d-flex mt-3 justify-content-center align-items-center flex-column"
            style="gap: 10px"
          >
            <button
              class="btn-ai mb-2"
              :disabled="!predictId"
              style="text-transform: initial; width: 200px"
              @click="changeColor()"
            >
              Progress
            </button>
          </div>
        </div>
        <div
          v-if="!!result"
          class="d-flex w-100 flex-column justify-content-center align-items-center"
          style="gap: 20px"
        >
          <img width="600" :src="result" alt="result" srcset="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'DecorationHouse',
  data() {
    return {
      contact: this.$t('contact'),
      loadingAI: false,
      predictId: null,
      roofColor: '#ff0000',
      wallColor: '#03ff00',
      doorColor: '#0000ff',
      result: null,
      loading: false
    };
  },
  components: { Footer },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  methods: {
    changeColor() {
      const windowWidth = window.screen.width;
      const body = {
        predictId: this.predictId,
        roofColor: this.roofColor,
        wallColor: this.wallColor,
        doorColor: this.doorColor
      };
      axios({
        url: `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict/${
          windowWidth > 768 ? 'change-color-web' : 'change-color'
        }`,
        method: 'POST',
        data: body
      }).then((data) => {
        const stringbase64 = `data:image/png;base64,${data.data}`;
        this.result = stringbase64;
      });
    }
  },
  mounted() {
    let props = this;
    // const loadRGBdata = (image) => {
    //   const canvas = document.createElement('canvas');
    //   // Set the width and height of the canvas to match the dimensions of the image
    //   canvas.width = 1200;
    //   canvas.height = 800;
    //   // Get the 2D context of the canvas
    //   const context = canvas.getContext('2d');
    //   // Draw the image onto the canvas
    //   context.drawImage(image, 0, 0);
    //   // Get the image data from the canvas
    //   const imageData = context.getImageData(0, 0, 1200, 800);
    //   // Get the pixel data from the image data
    //   const pixelData = imageData.data;
    //   // Create a new empty array to store the RGB data
    //   const rgbData = new Array(1200 * 800 * 3);
    //   // Loop over each pixel in the image data and convert it to RGB format
    //   for (let i = 0; i < pixelData.length; i += 4) {
    //     // Get the RGB values for the current pixel
    //     const r = pixelData[i];
    //     const g = pixelData[i + 1];
    //     const b = pixelData[i + 2];
    //     // Calculate the index for the current pixel in the RGB data array
    //     const index = (i / 4) * 3;
    //     // Set the RGB values for the current pixel in the RGB data array
    //     rgbData[index] = r;
    //     rgbData[index + 1] = g;
    //     rgbData[index + 2] = b;
    //   }
    //   // Reshape the RGB data into a 3D array with shape [height, width, 3]
    //   const rgbArray = new Array(image.height);
    //   for (let i = 0; i < image.height; i++) {
    //     rgbArray[i] = new Array(image.width);
    //     for (let j = 0; j < image.width; j++) {
    //       const index = (i * image.width + j) * 3;
    //       rgbArray[i][j] = [
    //         rgbData[index],
    //         rgbData[index + 1],
    //         rgbData[index + 2],
    //       ];
    //     }
    //   }
    //   // The RGB array is now ready to use
    //   return rgbArray;
    // };
    // const hexToRgb = (hex) => {
    //   // Remove the # symbol if it exists
    //   hex = hex.replace('#', '');
    //   // Convert the hex code to RGB values
    //   const r = parseInt(hex.substring(0, 2), 16);
    //   const g = parseInt(hex.substring(2, 4), 16);
    //   const b = parseInt(hex.substring(4, 6), 16);
    //   // Return the RGB values as an object
    //   return [r, g, b];
    // };
    // const getVectorColor = (originRGB, maskRGB, objectColor, targetColor) => {
    //   let redValue = 0;
    //   let greenValue = 0;
    //   let blueValue = 0;
    //   let numOfPixel = 0;
    //   const height = originRGB.length;
    //   const width = originRGB[0].length;
    //   for (let i = 0; i < height; i++) {
    //     for (let j = 0; j < width; j++) {
    //       const maskPixel = maskRGB[i][j];
    //       const isRedEqual = maskPixel[0] === objectColor[0];
    //       const isGreenEqual = maskPixel[1] === objectColor[1];
    //       const isBlueEqual = maskPixel[2] === objectColor[2];
    //       if (isRedEqual && isGreenEqual && isBlueEqual) {
    //         const originPixel = originRGB[i][j];
    //         numOfPixel += 1;
    //         redValue += originPixel[0];
    //         greenValue += originPixel[1];
    //         blueValue += originPixel[2];
    //       }
    //     }
    //   }
    //   const redAve = redValue / numOfPixel;
    //   const greenAve = greenValue / numOfPixel;
    //   const blueAve = blueValue / numOfPixel;
    //   const vectorRed = targetColor[0] - redAve;
    //   const vectorGreen = targetColor[1] - greenAve;
    //   const vectorBlue = targetColor[2] - blueAve;
    //   return [vectorRed, vectorGreen, vectorBlue];
    // };
    // //Prevent bad number because floating number
    // const roundNumber = (num) => {
    //   const rounded = Math.round(num);
    //   return rounded < 0 ? 0 : rounded > 255 ? 255 : rounded;
    // };
    // const getTranferedColor = (originPixel, vector) => {
    //   const [originRed, originGreen, originBlue] = originPixel;
    //   const [vectorRed, vectorGreen, vectorBlue] = vector;
    //   const redMoved = originRed + vectorRed;
    //   const greenMoved = originGreen + vectorGreen;
    //   const blueMoved = originBlue + vectorBlue;
    //   const redSafeMove = roundNumber(redMoved);
    //   const greenSafeMove = roundNumber(greenMoved);
    //   const blueSafeMove = roundNumber(blueMoved);
    //   const safeMovedPixel = [redSafeMove, greenSafeMove, blueSafeMove];
    //   return safeMovedPixel;
    // };
    // const changeColor = (
    //   originRGB,
    //   maskRGB,
    //   hexRoofColor,
    //   hexWallColor,
    //   hexDoorColor
    // ) => {
    //   const redColor = [255, 0, 0];
    //   const greenColor = [0, 255, 0];
    //   const blueColor = [0, 0, 255];
    //   const roofColor = hexToRgb(hexRoofColor);
    //   const wallColor = hexToRgb(hexWallColor);
    //   const doorColor = hexToRgb(hexDoorColor);
    //   const vectorRoofColor = getVectorColor(
    //     originRGB,
    //     maskRGB,
    //     redColor,
    //     roofColor
    //   );
    //   const vectorWallColor = getVectorColor(
    //     originRGB,
    //     maskRGB,
    //     greenColor,
    //     wallColor
    //   );
    //   const vectorDoorColor = getVectorColor(
    //     originRGB,
    //     maskRGB,
    //     blueColor,
    //     doorColor
    //   );
    //   const imageHeight = originRGB.length;
    //   const imageWidth = originRGB[0].length;
    //   const resultRGB = [];
    //   for (let heightIndex = 0; heightIndex < imageHeight; heightIndex++) {
    //     resultRGB.push([]);
    //     for (let widthIndex = 0; widthIndex < imageWidth; widthIndex++) {
    //       resultRGB[heightIndex].push([0, 0, 0]);
    //       const maskPixel = maskRGB[heightIndex][widthIndex];
    //       const originPixel = originRGB[heightIndex][widthIndex];
    //       const [maskRed, maskGreen, maskBlue] = maskPixel;
    //       if (maskPixel.every((colorValue) => colorValue === 0)) {
    //         resultRGB[heightIndex][widthIndex] =
    //           originRGB[heightIndex][widthIndex];
    //         continue;
    //       }
    //       if (maskRed === 255) {
    //         //Is roof pixel
    //         const tranferedPixel = getTranferedColor(
    //           originPixel,
    //           vectorRoofColor
    //         );
    //         resultRGB[heightIndex][widthIndex] = tranferedPixel;
    //         continue;
    //       }
    //       if (maskGreen === 255) {
    //         //Is wall pixel
    //         const tranferedPixel = getTranferedColor(
    //           originPixel,
    //           vectorWallColor
    //         );
    //         resultRGB[heightIndex][widthIndex] = tranferedPixel;
    //         continue;
    //       }
    //       if (maskBlue === 255) {
    //         //Is door pixel
    //         const tranferedPixel = getTranferedColor(
    //           originPixel,
    //           vectorDoorColor
    //         );
    //         resultRGB[heightIndex][widthIndex] = tranferedPixel;
    //         continue;
    //       }
    //     }
    //   }
    //   return resultRGB;
    // };
    // const displayRGBData = (canvasEle, pixelData) => {
    //   // Set the canvas dimensions to match the RGB array
    //   canvasEle.width = pixelData[0].length;
    //   canvasEle.height = pixelData.length;
    //   const ctx = canvasEle.getContext('2d');
    //   // Create a new ImageData object from the RGB array
    //   const imageData = ctx.createImageData(canvasEle.width, canvasEle.height);
    //   // Loop over each pixel in the RGB array and set the corresponding pixel in the ImageData object
    //   for (let i = 0; i < imageData.data.length; i += 4) {
    //     // Calculate the x and y position of the current pixel in the RGB array
    //     const x = (i / 4) % canvasEle.width;
    //     const y = Math.floor(i / 4 / canvasEle.width);
    //     // Get the RGB values for the current pixel in the RGB array
    //     const [r, g, b] = pixelData[y][x];
    //     // Set the RGBA values for the current pixel in the ImageData object
    //     imageData.data[i] = r;
    //     imageData.data[i + 1] = g;
    //     imageData.data[i + 2] = b;
    //     imageData.data[i + 3] = 255;
    //   }
    //   // Set the ImageData object as the canvas data
    //   ctx.putImageData(imageData, 0, 0);
    // };

    function initImageUpload(box) {
      let uploadField = box.querySelector('.image-upload');

      uploadField?.addEventListener('change', getFile);

      function getFile(e) {
        let file = e.currentTarget.files[0];
        checkType(file);
      }

      function previewImage(file) {
        // let img = box.querySelector('#img-preview');
        let thumb = box.querySelector('.js--image-preview'),
          reader = new FileReader();
        reader.onload = function () {
          // thumb.style.backgroundImage = 'url(' + reader.result + ')';
          thumb.src = reader.result;
          // console.log(img, reader.result);
        };
        reader.readAsDataURL(file);
        thumb.className += ' js--no-default';
      }

      function previewAIImage(url) {
        let thumb = document.querySelector('.ai--image-preview');
        let btnAI = document.querySelector('.ai-options');
        // thumb.style.backgroundImage = 'url(' + url + ')';
        thumb.src = url;
        btnAI.style.backgroundColor = 'var(--blue-text)';
        btnAI.style.pointerEvents = 'unset';
        // document.getElementById('loading-ai').style.display = 'none';
        props.loading = false;
      }

      function checkType(file) {
        // console.log(file);
        let imageType = /image.*/;
        if (!file.type.match(imageType)) {
          throw 'File is not an image';
        } else if (!file) {
          throw 'No image selected';
        } else {
          previewImage(file);
          // document.getElementById('loading-ai').style.display = 'grid';
          props.loading = true;
          const bodyFormData = new FormData();
          bodyFormData.append('file', file);
          props.predictId = null;
          props.result = null;

          axios({
            url: 'https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict/upload-input',
            method: 'POST',
            data: bodyFormData
          })
            .then(async (data) => {
              const inputName = data?.data?.data;
              axios({
                url: `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict`,
                data: { inputName, md_name: 'change_color' },
                method: 'POST'
              })
                .then((data) => {
                  // console.log(data);
                  props.predictId = data.data.request_id;
                  const intervalGetData = setInterval(() => {
                    axios({
                      url: `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict/get-results/${data.data.request_id}`,
                      method: 'GET'
                    })
                      .then((data) => {
                        const mask_url = data?.data?.data?.mask;
                        if (mask_url) {
                          clearInterval(intervalGetData);
                          previewAIImage(
                            `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/result/${mask_url}`
                          );
                        }
                      })
                      .catch(() => {
                        props.loading = false;
                      });
                  }, 2000);
                })
                .catch(() => {
                  props.loading = false;
                });
            })
            .catch(() => {
              // document.getElementById('loading-ai').style.display = 'none';
              props.loading = false;
            });
        }
      }
    }

    // initialize box-scope
    var boxes = document.querySelectorAll('.box');

    for (let i = 0; i < boxes.length; i++) {
      let box = boxes[i];
      initImageUpload(box);
    }

    let eleColorRoof = document.getElementById('value-color-roof');
    let eleColorWall = document.getElementById('value-color-wall');
    let eleColorDoor = document.getElementById('value-color-door');
    let eleInputColorRoof = document.getElementById('input-color-roof');
    let eleInputColorWall = document.getElementById('input-color-wall');
    let eleInputColorDoor = document.getElementById('input-color-door');
    // let resultCanvas = document.getElementById('image-result');

    eleColorRoof.innerHTML = this.roofColor;
    eleColorWall.innerHTML = this.wallColor;
    eleColorDoor.innerHTML = this.doorColor;
    eleInputColorRoof.setAttribute('value', this.roofColor);
    eleInputColorWall.setAttribute('value', this.wallColor);
    eleInputColorDoor.setAttribute('value', this.doorColor);
    eleInputColorRoof.addEventListener('change', (event) => {
      eleColorRoof.innerHTML = event.target.value;
      this.roofColor = event.target.value;
    });
    eleInputColorWall.addEventListener('change', (event) => {
      eleColorWall.innerHTML = event.target.value;
      this.wallColor = event.target.value;
    });
    eleInputColorDoor.addEventListener('change', (event) => {
      eleColorDoor.innerHTML = event.target.value;
      this.doorColor = event.target.value;
    });

    // let originRGB = null;
    // let maskRGB = null;
    // const originImage = document.getElementById('origin-image');
    // const maskImage = document.getElementById('mask-image');
    // originImage.onload = () => {
    //   originRGB = loadRGBdata(originImage);
    //   if (maskRGB != null) {
    //     const resultRGB = changeColor(
    //       originRGB,
    //       maskRGB,
    //       this.roofColor,
    //       this.wallColor,
    //       this.doorColor
    //     );
    //     displayRGBData(resultCanvas, resultRGB);
    //   }
    // };
    // maskImage.onload = () => {
    //   maskRGB = loadRGBdata(maskImage);
    //   if (originRGB != null) {
    //     const resultRGB = changeColor(
    //       originRGB,
    //       maskRGB,
    //       this.roofColor,
    //       this.wallColor,
    //       this.doorColor
    //     );
    //     displayRGBData(resultCanvas, resultRGB);
    //   }
    // };
  },
  watch: {
    lang() {
      this.contact = this.$t('contact');
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-us {
  width: 100%;
  min-height: 100vh;
  padding: 50px 0 50px;
  background: url('/images/bg/background.png');
  background-repeat: no-repeat;
  background-size: cover;

  .btn-ai {
    border: none;
    font-size: 16px;
    font-weight: 700;
    height: 48px !important;
    text-align: center;
    background-color: var(--orange) !important;
    color: #fff;
    border-radius: 50px;
    &.btn-reset {
      border: 1px solid var(--orange) !important;
      background-color: #fff !important;
      color: var(--orange) !important;
    }
  }
  .contact-us__container {
    width: 80%;
    margin: auto;
    padding: 80px 0;
    padding-top: 120px;
  }
  .contact-us__content {
    h2 {
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      z-index: 0;
      text-transform: none;
      text-align: center;
    }
    span {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
    }
    &.form {
      display: flex;
      justify-content: space-between;
      gap: 5%;
      .title-form {
        font-weight: 700;
        font-size: 40px;
        color: var(--blue-text);
      }
      img {
        border-radius: 10px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .contact-us__block {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    gap: 100px;
    flex-direction: column;
  }
  .contact-us__items {
    display: flex;
    //flex-direction: column;
    gap: 32px;
    width: 100%;
    img {
      height: 40px;
    }
  }
  .contact-us__item {
    flex: 1;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    color: var(--black-text);
    &:hover {
      text-decoration: none;
    }
  }
  .contact-us__item--content {
    h3 {
      font-weight: 700;
      font-size: 18px;
      color: var(--grey-text);
    }
    p {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
  }
  .contact-us__form {
    position: relative;
    width: 50%;
    min-width: 400px;
    //border: 2px solid var(--orange);
    border-radius: 10px;
    background-color: transparent;
    padding-top: 50px;
    &::after {
      content: '';
      position: absolute;
      top: -33%;
      right: -12%;
      width: 300px;
      height: 250px;
      background-image: url('/images/bg/penguin.png');
      background-size: cover;
      display: none;
    }
  }
  @media (max-width: 1024px) {
    & {
      height: unset;
    }
    .contact-us__block {
      flex-direction: column;
      gap: 60px;
    }
    .contact-us__items,
    .contact-us__form {
      flex-direction: column;
      width: 100%;
    }
    .contact-us__content {
      width: 100%;
      flex-direction: column;
      gap: 20px !important;
    }
  }
  @media (max-width: 768px) {
    .contact-us__container {
      padding-top: 100px;
    }

    & {
      padding-top: 0;
    }
    .contact-us__form {
      min-width: 0;
      padding: 10px;
      &::after {
        display: none;
      }
    }
    .contact-us__content {
      margin-top: 50px;
      h2 {
        font-size: 32px;
      }
      &.form {
        .title-form {
          font-size: 32px;
        }
      }
    }
  }

  .wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .box {
    display: block;
    min-width: 400px;
    height: max-content;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
  }

  .upload-options,
  .ai-options {
    position: relative;
    height: 75px;
    background-color: var(--blue-text);
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background-color ease-in-out 150ms;
    & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    & label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      &::after {
        content: 'Upload';
        position: absolute;
        font-size: 1.5rem;
        font-weight: 600;
        color: rgba(230, 230, 230, 1);
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
      }
      & span {
        display: inline-block;
        width: 50%;
        height: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
        &:hover i.material-icons {
          color: lightgray;
        }
      }
    }
  }
  .ai-options {
    display: none;
    pointer-events: none;
    background-color: grey;
    & label::after {
      content: 'AI';
    }
  }

  .js--image-preview,
  .ai--image-preview {
    height: 325px;
    width: 400px;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    &::after {
      position: relative;
      font-size: 4.5em;
      color: rgba(230, 230, 230, 1);
      top: calc(50% - 3rem);
      left: calc(50% - 2.25rem);
      z-index: 0;
    }
    &.js--no-default::after {
      display: none;
    }
  }

  i.material-icons {
    transition: color 100ms ease-in-out;
    font-size: 2.25em;
    line-height: 55px;
    color: white;
    display: block;
  }

  .animate {
    animation: ripple 0.4s linear;
  }

  @keyframes ripple {
    100% {
      opacity: 0;
      transform: scale(2.5);
    }
  }
  //Loader
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    margin-top: 50px;
    padding: 2% 5%;

    .txt-queue {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 8px;
      text-align: center;
    }
    .loader-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-gap: 2px;
      width: 100px;
      height: 100px;
    }
    .loader-container > div {
      position: relative;
      width: 100%;
      height: 100%;
      background: tomato;
      transform: scale(0);
      transform-origin: center center;
      animation: loader 2s infinite linear;
    }
    .loader-container > div:nth-of-type(1),
    .loader-container > div:nth-of-type(5),
    .loader-container > div:nth-of-type(9) {
      animation-delay: 0.4s;
    }
    .loader-container > div:nth-of-type(4),
    .loader-container > div:nth-of-type(8) {
      animation-delay: 0.2s;
    }
    .loader-container > div:nth-of-type(2),
    .loader-container > div:nth-of-type(6) {
      animation-delay: 0.6s;
    }
    .loader-container > div:nth-of-type(3) {
      animation-delay: 0.8s;
    }
    @media (max-width: 768px) {
      .examper-wrapper {
        flex-direction: column;
      }
    }
  }

  @keyframes loader {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  .color-wrapper {
    position: relative;
    width: 250px;
    margin: 20px auto;
  }

  .color-wrapper p {
    margin-bottom: 5px;
  }

  input.call-picker {
    border: 1px solid #aaa;
    color: #666;
    text-transform: uppercase;
    float: left;
    outline: none;
    padding: 10px;
    text-transform: uppercase;
    width: 85px;
  }

  .color-picker {
    background: white;
    box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    span {
      padding: 4px 0px 0px 12px;
      font-weight: 500;
    }
  }

  .color-holder {
    background: #fff;
    cursor: pointer;
    border: 1px solid #aaa;
    width: 40px;
    height: 36px;
    float: left;
    margin-left: 5px;
  }

  .color-item {
    cursor: pointer;
    width: 10px;
    height: 10px;
    list-style-type: none;
    float: left;
    margin: 2px;
    border: 1px solid #ddd;
  }

  .color-item:hover {
    border: 1px solid #666;
    opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=8);
  }
  /////
  .input-color {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 6em;
    padding: 0;
    width: 6em;
  }
}
</style>
