<template>
  <div>
    <div class="contact-us">
      <div class="contact-us__container" id="wrapper-ai">
        <div class="contact-us__content">
          <h2>{{ decoHouse.title }}</h2>
          <p class="text-center">{{ decoHouse.subtitle }}</p>
        </div>
        <div v-if="loading" class="loader" id="loading-ai">
          <p class="mt-3 txt-queue">
            {{
              queue === 0 || queue === undefined
                ? decoHouse.inProgress
                : `${decoHouse.queueBefore} ${queue} ${decoHouse.queueAfter}`
            }}
          </p>
          <div class="ai-house-bar">
            <strong class="text-white">{{ Math.ceil(percentProgess) }}%</strong>
          </div>
          <!-- <v-progress-linear
            :value="percentProgess"
            height="16"
            striped
            color="deep-orange"
            style="background: grey"
          >
            <strong class="text-white"
              >{{ Math.ceil(percentProgess) }}%</strong
            ></v-progress-linear
          > -->
          <div
            class="examper-wrapper d-flex align-items-center mx-auto mt-5"
            style="gap: 30px"
          >
            <div class="img-example">
              <div class="overlay">
                {{ decoHouse.origin }}
              </div>
              <img
                :src="dfPic[pic] || demoImg[0]"
                width="100%"
                alt="marioPhoto 1"
              />
            </div>
            <div class="img-example">
              <div class="overlay">
                {{ decoHouse.output }}
              </div>
              <img
                :src="outputEx || demoImg[1]"
                width="100%"
                alt="marioPhoto 2"
              />
            </div>
          </div>
        </div>
        <div
          v-bind:style="{
            visibility: !loading ? 'visible' : 'hidden',
            position: !loading ? 'unset' : 'fixed'
          }"
          class="ai-house-body"
        >
          <div
            class="ai-house-card mx-auto p-2"
            style="width: max-content; height: max-content"
          >
            <div class="wrapper">
              <div class="pb-2 form-ai-house">
                <div class="form-ai-house__group">
                  <p class="form-ai-house__label mb-1 font-bold">
                    <b>{{ decoHouse.step1 }}</b
                    >{{ decoHouse.optionDecoration }}
                  </p>
                  <div class="group-btn-decoHouse">
                    <input
                      type="radio"
                      name="type"
                      value="EXTERIOR"
                      id="Exterior"
                      v-model="dfOption"
                    />
                    <input
                      type="radio"
                      name="type"
                      id="Interior"
                      value="INTERIOR"
                      v-model="dfOption"
                    />
                    <label for="Exterior" class="option option-1">
                      <span> {{ decoHouse.exterior }}</span>
                    </label>
                    <label for="Interior" class="option option-2">
                      <span> {{ decoHouse.interior }}</span>
                    </label>
                  </div>
                </div>
                <div class="ai-container d-flex">
                  <div class="ai-container-upload d-flex flex-column">
                    <p class="form-ai-house__label mb-1 mr-2 font-bold">
                      <b>{{ decoHouse.step2 }}</b
                      >{{ decoHouse.labelUpload }}
                    </p>
                    <div class="box">
                      <img
                        id="origin-image"
                        class="js--image-preview"
                        :src="dfimg"
                      />
                      <div class="upload-options">
                        <label
                          v-bind:style="{
                            visibility: pic !== undefined ? 'hidden' : 'visible'
                          }"
                        >
                          <input
                            type="file"
                            class="image-upload"
                            accept="image/*"
                          />
                          <img width="40" :src="iconUpload" />
                          <span class="btn-upload-ai">{{
                            decoHouse.upload
                          }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="text-or">
                      {{ decoHouse.contentChoosePic }}
                    </span>
                  </div>
                  <div class="choose-pic">
                    <p class="form-ai-house__label mb-1 mr-2 font-bold">
                      {{ decoHouse.labelSample }}
                    </p>
                    <div class="group-btn-decoHouse--image">
                      <input
                        type="radio"
                        name="pic"
                        :value="0"
                        id="dfPic1"
                        v-model="pic"
                      />
                      <input
                        type="radio"
                        name="pic"
                        id="dfPic2"
                        :value="1"
                        v-model="pic"
                      />
                      <label for="dfPic1" class="option option-1">
                        <img width="115" height="75" :src="dfPic[0]" />
                      </label>
                      <label for="dfPic2" class="option option-2">
                        <img width="115" height="75" :src="dfPic[1]" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-ai-house__group">
                  <p class="form-ai-house__label mb-2">
                    <b>{{ decoHouse.step3 }}</b>
                  </p>
                  <div class="group-btn-decoHouse--theme">
                    <div v-for="(theme, indx) in lstTheme" :key="indx">
                      <input
                        type="radio"
                        name="theme"
                        :value="theme.id"
                        :id="`theme${indx}`"
                        v-model="dfTheme"
                      />
                      <label
                        :for="`theme${indx}`"
                        :class="`option option-${indx}`"
                      >
                        <div class="options-theme-item__content">
                          <img :src="theme.descriptImg || dftheme" />
                          <span>
                            {{ theme.id }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="input-data mt-4">
                    <input
                      type="text"
                      v-model="designConcept"
                      placeholder=" "
                      required
                    />
                    <div class="underline"></div>
                    <label>{{ decoHouse.theme }}</label>
                  </div>
                  <span
                    >{{ decoHouse.ex }}Craftsman, Luxury, Tricorn
                    Black,...</span
                  >

                  <p class="form-ai-house__label mb-1">
                    <b>{{ decoHouse.step4 }}</b
                    >{{ decoHouse.number }}
                  </p>
                  <div class="group-btn-decoHouse--number">
                    <div v-for="n in 3" :key="n">
                      <input
                        type="radio"
                        name="number"
                        :value="n"
                        :id="`number${n}`"
                        v-model="numberPic"
                      />
                      <label :for="`number${n}`" :class="`option option-${n}`">
                        <span>{{ n }}</span>
                      </label>
                    </div>
                  </div>
                  <i>{{ decoHouse.note }}</i>
                </div>
                <span class="msg-err" v-if="err_theme">{{ err_theme }}</span>
                <button
                  v-if="!err_theme"
                  :disabled="(pic === undefined && !designConcept) || !dfimg"
                  class="btn-ai mt-2"
                  @click="onSubmitAi"
                >
                  {{ decoHouse.btnAi }}
                </button>
                <p class="mt-3 msg-err" v-if="err_get_result">
                  {{ err_get_result }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="result.length === 0" class="comparison-slider-wrapper">
            <div class="comparison-slider">
              <div class="overlay">
                <!-- {{ decoHouse.output }} -->
              </div>
              <img :src="demoImg[1]" alt="marioPhoto 2" />
              <div class="resize">
                <!-- <div class="overlay">{{ decoHouse.origin }}</div> -->
                <img :src="demoImg[0]" alt="marioPhoto 1" />
              </div>
              <div class="divider">
                <div class="overlay drag">{{ decoHouse.drag }}</div>
              </div>
            </div>
          </div>
          <div v-if="result.length > 0" class="wrapper-result">
            <div class="d-flex" style="flex-wrap: wrap">
              <div
                class="container-result col-12 col-lg-6 p-3"
                :cols="12"
                :lg="6"
              >
                <div class="overlay">
                  {{ decoHouse.origin }}
                </div>
                <div class="overlay-result">
                  <div
                    class="d-flex flex-column h-100 w-100 align-items-center justify-content-center"
                    style="gap: 10px"
                  >
                    <button
                      :data-tooltip="decoHouse.zoom"
                      data-tooltip-location="left"
                      class="btn-download"
                      @click="
                        onZoomImage(
                          `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/origin/${fileOrigin}`
                        )
                      "
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.3471 16.1623C19.5827 14.4802 20.3125 12.4034 20.3125 10.1562C20.3125 4.54711 15.7654 0 10.1562 0C4.54711 0 0 4.54711 0 10.1562C0 15.7654 4.54711 20.3125 10.1562 20.3125C12.404 20.3125 14.4813 19.5823 16.1637 18.3461L16.1623 18.3471C16.2084 18.4096 16.2597 18.4694 16.3163 18.526L22.3327 24.5424C22.9428 25.1526 23.9322 25.1526 24.5424 24.5424C25.1526 23.9322 25.1526 22.9428 24.5424 22.3327L18.526 16.3163C18.4694 16.2597 18.4096 16.2084 18.3471 16.1623ZM18.75 10.1562C18.75 14.9024 14.9024 18.75 10.1562 18.75C5.41005 18.75 1.5625 14.9024 1.5625 10.1562C1.5625 5.41005 5.41005 1.5625 10.1562 1.5625C14.9024 1.5625 18.75 5.41005 18.75 10.1562Z"
                          fill="black"
                        />
                        <path
                          d="M10.1582 6.23529L10.1582 14.0814"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M14.0811 10.1584H6.2349"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <img
                  width="100%"
                  :src="`https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/origin/${fileOrigin}`"
                  :alt="img"
                />
              </div>
              <div
                class="container-result col-12 col-lg-6 p-3"
                v-for="img in result"
                :key="img"
              >
                <div class="overlay-result">
                  <div
                    class="d-flex flex-column h-100 w-100 align-items-center justify-content-center"
                    style="gap: 10px"
                  >
                    <button
                      :data-tooltip="decoHouse.zoom"
                      data-tooltip-location="left"
                      class="btn-download"
                      @click="
                        onZoomImage(
                          `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/result/${img}`
                        )
                      "
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.3471 16.1623C19.5827 14.4802 20.3125 12.4034 20.3125 10.1562C20.3125 4.54711 15.7654 0 10.1562 0C4.54711 0 0 4.54711 0 10.1562C0 15.7654 4.54711 20.3125 10.1562 20.3125C12.404 20.3125 14.4813 19.5823 16.1637 18.3461L16.1623 18.3471C16.2084 18.4096 16.2597 18.4694 16.3163 18.526L22.3327 24.5424C22.9428 25.1526 23.9322 25.1526 24.5424 24.5424C25.1526 23.9322 25.1526 22.9428 24.5424 22.3327L18.526 16.3163C18.4694 16.2597 18.4096 16.2084 18.3471 16.1623ZM18.75 10.1562C18.75 14.9024 14.9024 18.75 10.1562 18.75C5.41005 18.75 1.5625 14.9024 1.5625 10.1562C1.5625 5.41005 5.41005 1.5625 10.1562 1.5625C14.9024 1.5625 18.75 5.41005 18.75 10.1562Z"
                          fill="black"
                        />
                        <path
                          d="M10.1582 6.23529L10.1582 14.0814"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M14.0811 10.1584H6.2349"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      :data-tooltip="decoHouse.download"
                      data-tooltip-location="left"
                      class="btn-download"
                      @click="
                        onDownloadImage(
                          `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/result/${img}`,
                          img
                        )
                      "
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 14.85C1.16421 14.85 1.5 15.1858 1.5 15.6V19.35C1.5 20.1785 2.17157 20.85 3 20.85H21C21.8284 20.85 22.5 20.1785 22.5 19.35V15.6C22.5 15.1858 22.8358 14.85 23.25 14.85C23.6642 14.85 24 15.1858 24 15.6V19.35C24 21.0069 22.6569 22.35 21 22.35H3C1.34315 22.35 0 21.0069 0 19.35V15.6C0 15.1858 0.335786 14.85 0.75 14.85Z"
                          fill="black"
                        />
                        <path
                          d="M11.4697 17.7803C11.7626 18.0732 12.2374 18.0732 12.5303 17.7803L17.0303 13.2803C17.3232 12.9874 17.3232 12.5126 17.0303 12.2197C16.7374 11.9268 16.2626 11.9268 15.9697 12.2197L12.75 15.4393V2.25C12.75 1.83579 12.4142 1.5 12 1.5C11.5858 1.5 11.25 1.83579 11.25 2.25V15.4393L8.03033 12.2197C7.73744 11.9268 7.26256 11.9268 6.96967 12.2197C6.67678 12.5126 6.67678 12.9874 6.96967 13.2803L11.4697 17.7803Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <img
                  width="100%"
                  :src="`https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/result/${img}`"
                  :alt="img"
                />
              </div>
            </div>
            <button text class="btn-ai btn-reset mt-3" @click="result = []">
              {{ decoHouse.reset }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal" v-if="dialog">
        <div class="modal-content">
          <div
            class="d-flex justify-content-end"
            style="gap: 8px; margin-bottom: 8px"
          >
            <button
              style="text-transform: initial"
              @click="
                onDownloadImage(
                  `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/public/result/${img}`,
                  img
                )
              "
            >
              {{ decoHouse.download }}
            </button>
            <button style="text-transform: initial" @click="dialog = false">
              {{ decoHouse.close }}
            </button>
          </div>
          <img width="100%" :src="zoomImg" :alt="img" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import exterior1 from '../../public/images/decoration-house/exterior-sample-1.jpg';
import exterior2 from '../../public/images/decoration-house/exterior-sample-2.jpg';
import interior1 from '../../public/images/decoration-house/interior-sample-1.jpg';
import result_interior1 from '../../public/images/decoration-house/interior-result-1.png';
import interior2 from '../../public/images/decoration-house/interior-sample-2.jpg';
import iconUpload from '../../public/images/icons/decoration-house/icon-upload.svg';
import dftheme from '../../public/images/decoration-house/default-theme.png';
import ex0_12 from '../../public/images/decoration-house/0_12.png';
import ex0_13 from '../../public/images/decoration-house/0_13.png';
import ex0_15 from '../../public/images/decoration-house/0_15.png';
import ex0_16 from '../../public/images/decoration-house/0_16.png';
import ex0_17 from '../../public/images/decoration-house/0_17.png';
import ex0_18 from '../../public/images/decoration-house/0_18.png';
import ex1_12 from '../../public/images/decoration-house/1_12.png';
import ex1_13 from '../../public/images/decoration-house/1_13.png';
import ex1_15 from '../../public/images/decoration-house/1_15.png';
import ex1_16 from '../../public/images/decoration-house/1_16.png';
import ex1_17 from '../../public/images/decoration-house/1_17.png';
import ex1_18 from '../../public/images/decoration-house/1_18.png';

import Holywood from '../../public/images/decoration-house/0_Holywood.png';
import Japandi from '../../public/images/decoration-house/0_Japandi.png';
import Modern from '../../public/images/decoration-house/0_Modern.png';
import Craftman from '../../public/images/decoration-house/1_Craftman.png';
import Luxury from '../../public/images/decoration-house/1_Luxury.png';
import TricornBlack from '../../public/images/decoration-house/1_TricornBlack.png';

const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'DecorationHouse',
  data() {
    return {
      lstExample: [
        { pic: 0, theme: 12, imgEx: ex0_12 },
        { pic: 0, theme: 13, imgEx: ex0_13 },
        { pic: 0, theme: 15, imgEx: ex0_15 },
        { pic: 0, theme: 16, imgEx: ex0_16 },
        { pic: 0, theme: 17, imgEx: ex0_17 },
        { pic: 0, theme: 18, imgEx: ex0_18 },
        { pic: 1, theme: 12, imgEx: ex1_12 },
        { pic: 1, theme: 13, imgEx: ex1_13 },
        { pic: 1, theme: 15, imgEx: ex1_15 },
        { pic: 1, theme: 16, imgEx: ex1_16 },
        { pic: 1, theme: 17, imgEx: ex1_17 },
        { pic: 1, theme: 18, imgEx: ex1_18 }
      ],
      outputEx: null,
      dftheme: dftheme,
      iconUpload: iconUpload,
      tab: 'tab-1',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      intervelCountdown: null,
      firstCall: true,
      loading: false,
      initCountdown: 0,
      countDown: 0,
      queue: 0,
      totalQueue: 0,
      reveal: false,
      dfimg:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
      dfPic: [exterior1, exterior2],
      pic: undefined,
      decoHouse: this.$t('decoration_house'),
      contact: this.$t('contact'),
      loadingAI: false,
      dfOption: 'EXTERIOR',
      dfTheme: '',
      numberPic: 1,
      lstTheme: [
        { id: 'Craftman', descriptImg: Craftman },
        { id: 'Luxury', descriptImg: Luxury },
        { id: 'TricornBlack', descriptImg: TricornBlack }
      ],
      interiorTheme: [
        { id: 'Holywood', descriptImg: Holywood },
        { id: 'Japandi', descriptImg: Japandi },
        { id: 'Modern', descriptImg: Modern }
      ],
      exteriorTheme: [
        { id: 'Craftman', descriptImg: Craftman },
        { id: 'Luxury', descriptImg: Luxury },
        { id: 'TricornBlack', descriptImg: TricornBlack }
      ],
      fileName: null,
      fileOrigin: null,
      dialog: false,
      result: [],
      err_theme: '',
      err_get_result: '',
      demoImg: [interior1, result_interior1],
      zoomImg: null,
      percentProgess: 0,
      query: false,
      show: true,
      formData: new FormData(),
      n: 1,
      designConcept: ''
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  components: {
    Footer
  },
  methods: {
    previewImage(box, file) {
      let thumb = box.querySelector('.js--image-preview'),
        reader = new FileReader();
      reader.onload = function () {
        thumb.src = reader.result;
      };
      reader.readAsDataURL(file);
      thumb.className += ' js--no-default';
      this.pic = 9999;
    },
    onZoomImage(img) {
      this.dialog = true;
      this.zoomImg = img;
    },
    onDownloadImage(url, fileName) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
        });
    },
    drags(dragElement, resizeElement, container) {
      let touched = false;
      window.addEventListener('touchstart', function () {
        touched = true;
      });
      window.addEventListener('touchend', function () {
        touched = false;
      });

      dragElement.addEventListener('mousedown', startDragging);
      dragElement.addEventListener('touchstart', startDragging);

      function startDragging(e) {
        dragElement.classList.add('draggable');
        resizeElement.classList.add('resizable');

        let startX = e.pageX ? e.pageX : e.touches[0].pageX;
        let dragWidth = dragElement.offsetWidth;
        let posX = dragElement.offsetLeft + dragWidth - startX;
        let containerOffset = container.offsetLeft;
        let containerWidth = container.offsetWidth;
        let minLeft = containerOffset + 10;
        let maxLeft = containerOffset + containerWidth - dragWidth - 10;

        document.addEventListener('mousemove', drag);
        document.addEventListener('touchmove', drag);
        document.addEventListener('mouseup', stopDragging);
        document.addEventListener('touchend', stopDragging);
        document.addEventListener('touchcancel', stopDragging);

        function drag(e) {
          if (!touched) {
            e.preventDefault();
          }

          let moveX = e.pageX ? e.pageX : e.touches[0].pageX;
          let leftValue = moveX + posX - dragWidth;

          if (leftValue < minLeft) {
            leftValue = minLeft;
          } else if (leftValue > maxLeft) {
            leftValue = maxLeft;
          }

          let widthValue =
            ((leftValue + dragWidth / 2 - containerOffset) * 100) /
              containerWidth +
            '%';

          dragElement.style.left = widthValue;
          dragElement.addEventListener('mouseup', stopDragging);
          resizeElement.style.width = widthValue;
        }

        function stopDragging() {
          dragElement.classList.remove('draggable');
          resizeElement.classList.remove('resizable');
          document.removeEventListener('mousemove', drag);
          document.removeEventListener('touchmove', drag);
          document.removeEventListener('mouseup', stopDragging);
          document.removeEventListener('touchend', stopDragging);
          document.removeEventListener('touchcancel', stopDragging);
        }
      }
    },
    onSubmitAi() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.err_get_result = '';
      this.loading = true;
      this.outputEx =
        this.lstExample.find(
          (item) => item.pic === this.pic && item.theme === this.dfTheme
        )?.imgEx || null;
      this.percentProgess = 0;

      axios({
        url: 'https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict/upload-input',
        method: 'POST',
        data: this.formData
      })
        .then((data) => {
          this.fileOrigin = data?.data?.data;
          this.fileName = data?.data?.data;
          const body = {
            inputName: this.fileName,
            designType: this.dfOption,
            designConcept: this.designConcept,
            numOfResult: this.numberPic,
            md_name: 'sd_design'
          };
          axios({
            url: `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict`,
            method: 'POST',
            data: body
          }).then((data) => {
            this.countDown = 0;
            this.initCountdown = 0;

            const predictId = data?.data?.request_id;
            if (predictId) {
              const intervalGetData = setInterval(() => {
                axios({
                  url: `https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/predict/get-results/${predictId}`,
                  method: 'GET'
                })
                  .then((data) => {
                    if (data?.data?.data?.images) {
                      this.percentProgess = 100;
                      this.countDown = 0;
                      this.initCountdown = 0;
                      this.reveal = true;

                      this.result = data?.data?.data?.images;
                      clearInterval(intervalGetData);
                      this.loading = false;
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    } else {
                      if (data?.data?.data?.inQueue !== null) {
                        this.queue = data?.data?.data?.inQueue;
                      }
                      if (this.countDown <= 0) {
                        if (
                          data?.data?.data?.initialCountDown !==
                          this.initCountdown
                        )
                          this.initCountdown =
                            data?.data?.data?.initialCountDown;
                        if (
                          data?.data?.data?.initialCountDown !== this.countDown
                        )
                          this.countDown = data?.data?.data?.initialCountDown;
                      }
                    }
                  })
                  .catch((error) => {
                    this.err_get_result =
                      error?.response?.data?.errorDetail?.message;
                    clearInterval(intervalGetData);
                    this.loading = false;
                  });
              }, 2000);
            }
          });
        })
        .catch((error) => {
          this.err_get_result = error?.response?.data?.errorDetail?.message;
        });
    }
  },
  mounted() {
    let props = this;
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        // set state to false
        props.dialog = false;
      }
    });
    axios({
      url: 'https://ai-house-design.tgl-cloud.com/stable-diffusion-layout/v1/',
      method: 'GET'
    })
      .then(() => {
        // this.lstTheme = data?.data?.data?.exterior;
        // this.dfTheme = this.lstTheme[0].id;
        // this.exteriorTheme = data?.data?.data?.exterior;
        // this.interiorTheme = data?.data?.data?.interior;
      })
      .catch(() => {
        this.err_theme = 'Server is down, please comeback later.';
      });

    function initImageUpload(box) {
      let uploadField = box.querySelector('.image-upload');
      uploadField?.addEventListener('change', getFile);
      function getFile(e) {
        let file = e.currentTarget.files[0];
        checkType(file);
      }

      function checkType(file) {
        let imageType = /image.*/;
        if (!file.type.match(imageType)) {
          throw 'File is not an image';
        } else if (!file) {
          throw 'No image selected';
        } else {
          props.pic = undefined;
          props.previewImage(box, file);
          props.formData = new FormData();
          props.formData.append('file', file);
        }
      }
    }
    // initialize box-scope
    var boxes = document.querySelectorAll('.box');
    for (let i = 0; i < boxes.length; i++) {
      let box = boxes[i];
      initImageUpload(box);
    }

    if (document.querySelector('.comparison-slider')) {
      let compSlider = document.querySelectorAll('.comparison-slider');

      // Let's loop through the sliders and initialise each of them
      compSlider.forEach(function (slider) {
        let compSliderWidth = slider.offsetWidth + 'px';
        slider.querySelector('.resize img').style.width = compSliderWidth;
        props.drags(
          slider.querySelector('.divider'),
          slider.querySelector('.resize'),
          slider
        );
      });

      // If the user resizes the window, let's update our variables and resize our images
      window.addEventListener('resize', function () {
        let compSliderWidth = compSlider.offsetWidth + 'px';
        if (compSlider)
          compSlider.querySelector('.resize img').style.width = compSliderWidth;
      });
    }
  },
  watch: {
    dfTheme() {
      this.designConcept = this.dfTheme;
    },
    result() {
      const drags = this.drags;
      if (this.result.length === 0) {
        setTimeout(() => {
          if (document.querySelector('.comparison-slider')) {
            let compSlider = document.querySelectorAll('.comparison-slider');

            // Let's loop through the sliders and initialise each of them
            compSlider.forEach(function (slider) {
              let compSliderWidth = slider.offsetWidth + 'px';
              slider.querySelector('.resize img').style.width = compSliderWidth;
              drags(
                slider.querySelector('.divider'),
                slider.querySelector('.resize'),
                slider
              );
            });

            // If the user resizes the window, let's update our variables and resize our images
            window.addEventListener('resize', function () {
              let compSliderWidth = compSlider.offsetWidth + 'px';
              compSlider.querySelector('.resize img').style.width =
                compSliderWidth;
            });
          }
        }, 300);
      }
    },
    percentProgess() {
      if (this.percentProgess === 0) {
        this.query = true;
      } else {
        this.query = false;
      }
    },
    countDown() {
      if (this.countDown > 0) {
        this.percentProgess =
          ((this.initCountdown - this.countDown) / this.initCountdown) * 100;
        if (this.percentProgess > 80) {
          this.n = 2;
          clearInterval(this.intervelCountdown);
          this.firstCall = true;
        } else if (this.percentProgess > 90) {
          this.n = 3;
          clearInterval(this.intervelCountdown);
          this.firstCall = true;
        } else this.n = 1;
        if (this.firstCall) {
          this.intervelCountdown = setInterval(() => {
            this.countDown = this.countDown - 1 / this.n;
            this.firstCall = false;
          }, 1000);
        }
      }
      if (this.countDown <= 0) {
        this.percentProgess = 100;
        clearInterval(this.intervelCountdown);
        this.firstCall = true;
      }
    },
    lang() {
      this.contact = this.$t('contact');
      this.decoHouse = this.$t('decoration_house');
    },
    dfOption() {
      let thumb = document.querySelector('.js--image-preview');
      thumb.src = this.dfimg;
      this.pic = undefined;
      this.fileName = null;

      switch (this.dfOption) {
        case 'EXTERIOR':
          this.lstTheme = this.exteriorTheme;
          this.dfPic = [exterior1, exterior2];
          break;
        case 'INTERIOR':
          this.lstTheme = this.interiorTheme;
          this.dfPic = [interior1, interior2];
          break;
      }
      // this.dfTheme = this.lstTheme[0].id;
    },
    pic() {
      const indexTab = this.tab === 'tab-1' ? 0 : 1;
      let thumb = document.querySelectorAll('.js--image-preview')[indexTab];
      if (this.pic !== undefined) {
        thumb.src = this.dfPic[this.pic];

        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const file = new File([blob], 'my-image.jpg', {
              type: 'image/jpeg'
            });
            this.formData = new FormData();
            this.formData.append('file', file);
          }, 'image/jpeg');
        };
        img.src = this.dfPic[this.pic];
      } else {
        this.pic = undefined;
        thumb.src = this.dfimg;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-progress-linear {
  border-radius: 10px;
  width: 80%;
  .deep-orange {
    background: var(--blue-text) !important;
  }
  .v-progress-linear__buffer {
    background: rgb(90, 90, 90);
  }
}
.contact-us {
  width: 100%;
  min-height: 100vh;
  padding: 50px 0 50px;
  background: url('/images/bg/bg-bene.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .ai-house-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    border-radius: 10px;
    width: calc(113px * 4);
    @media only screen and (max-width: 450px) {
      width: calc(113px * 3);
    }
    @media only screen and (max-width: 350px) {
      width: calc(113px * 2);
    }
    box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
    background-color: #2c3e50;
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      var(--orange) 40px,
      var(--orange) 80px
    );

    animation: slide 4s linear infinite;
    will-change: background-position;
  }
  @keyframes slide {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 113px;
    }
  }
  .ai-house-card {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 15px;
  }
  .group-btn-decoHouse {
    display: inline-flex;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
    .option {
      background: #fff;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 10px;
      border-radius: 5px;
      cursor: pointer;
      padding: 0 10px;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
    }
    .option .dot {
      height: 20px;
      width: 20px;
      background: #d9d9d9;
      border-radius: 50%;
      position: relative;
    }
    .option .dot::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: var(--blue-text);
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
    input[type='radio'] {
      display: none;
    }
    #Exterior:checked:checked ~ .option-1,
    #Interior:checked:checked ~ .option-2 {
      border-color: var(--blue-text);
      background: var(--blue-text);
    }
    #Exterior:checked:checked ~ .option-1 .dot,
    #Interior:checked:checked ~ .option-2 .dot {
      background: #fff;
    }
    #Exterior:checked:checked ~ .option-1 .dot::before,
    #Interior:checked:checked ~ .option-2 .dot::before {
      opacity: 1;
      transform: scale(1);
    }
    .wrapper .option span {
      font-size: 20px;
      color: #808080;
    }
    #Exterior:checked:checked ~ .option-1 span,
    #Interior:checked:checked ~ .option-2 span {
      color: #fff;
    }
  }
  .group-btn-decoHouse--image {
    display: flex;
    height: 200px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 0;
    .option {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      padding: 0;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .option .dot {
      height: 20px;
      width: 20px;
      background: #d9d9d9;
      border-radius: 50%;
      position: relative;
    }
    .option .dot::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: var(--blue-text);
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
    input[type='radio'] {
      display: none;
    }
    #dfPic1:checked:checked ~ .option-1,
    #dfPic2:checked:checked ~ .option-2 {
      border-color: var(--blue-text);
      background: var(--blue-text);
    }
    #dfPic1:checked:checked ~ .option-1 .dot,
    #dfPic2:checked:checked ~ .option-2 .dot {
      background: #fff;
    }
    #dfPic1:checked:checked ~ .option-1 .dot::before,
    #dfPic2:checked:checked ~ .option-2 .dot::before {
      opacity: 1;
      transform: scale(1);
    }
    .wrapper .option span {
      font-size: 20px;
      color: #808080;
    }
    #dfPic1:checked:checked ~ .option-1 span,
    #dfPic2:checked:checked ~ .option-2 span {
      color: #fff;
    }
  }
  .group-btn-decoHouse--theme {
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: space-evenly;
    border-radius: 5px;
    .option {
      background: #fff;
      height: 100%;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 10px;
      border-radius: 5px;
      cursor: pointer;
      padding: 8px 10px;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
      .options-theme-item__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
        padding: 0;
        span {
          text-transform: initial !important;
        }
        img {
          width: 100px;
          border-radius: 50%;
          border: 2px solid #ccc;
          height: 100px;
          object-fit: cover;
        }
      }
    }
    .option .dot {
      height: 20px;
      width: 20px;
      background: #d9d9d9;
      border-radius: 50%;
      position: relative;
    }
    .option .dot::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: var(--blue-text);
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
    input[type='radio'] {
      display: none;
    }
    #theme0:checked:checked ~ .option-0,
    #theme1:checked:checked ~ .option-1,
    #theme2:checked:checked ~ .option-2 {
      border-color: var(--blue-text);
      background: var(--blue-text);
    }
    #theme0:checked:checked ~ .option-0 .dot,
    #theme1:checked:checked ~ .option-1 .dot,
    #theme2:checked:checked ~ .option-2 .dot {
      background: #fff;
    }
    #theme0:checked:checked ~ .option-0 .dot::before,
    #theme1:checked:checked ~ .option-1 .dot::before,
    #theme2:checked:checked ~ .option-2 .dot::before {
      opacity: 1;
      transform: scale(1);
    }
    .wrapper .option span {
      font-size: 20px;
      color: #808080;
    }
    #theme0:checked:checked ~ .option-0 span,
    #theme1:checked:checked ~ .option-1 span,
    #theme2:checked:checked ~ .option-2 span {
      color: #fff;
    }
  }
  .group-btn-decoHouse--number {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    .option {
      background: #fff;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 10px;
      border-radius: 50%;
      cursor: pointer;
      padding: 0 10px;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
    }
    .option .dot {
      height: 20px;
      width: 20px;
      background: #d9d9d9;
      border-radius: 50%;
      position: relative;
    }
    .option .dot::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: var(--blue-text);
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
    input[type='radio'] {
      display: none;
    }
    #number1:checked:checked ~ .option-1,
    #number2:checked:checked ~ .option-2,
    #number3:checked:checked ~ .option-3 {
      border-color: var(--blue-text);
      background: var(--blue-text);
    }
    #number1:checked:checked ~ .option-1 .dot,
    #number2:checked:checked ~ .option-2 .dot,
    #number3:checked:checked ~ .option-3 .dot {
      background: #fff;
    }
    #number1:checked:checked ~ .option-1 .dot::before,
    #number2:checked:checked ~ .option-2 .dot::before,
    #number3:checked:checked ~ .option-3 .dot::before {
      opacity: 1;
      transform: scale(1);
    }
    .wrapper .option span {
      font-size: 20px;
      color: #808080;
    }
    #number1:checked:checked ~ .option-1 span,
    #number2:checked:checked ~ .option-2 span,
    #number3:checked:checked ~ .option-3 span {
      color: #fff;
    }
  }
  .img-example {
    flex: 1;
    position: relative;
    .overlay {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 10px;
      padding: 8px;
      color: #fff;
      background: black;
    }
  }
  .v-btn {
    letter-spacing: normal !important;
    text-transform: initial;
  }
  .v-tab {
    background: #bfbfbf;
  }
  .v-tab--active {
    background-color: #fff;
  }
  .ai-house-body {
    display: flex;
    gap: 50px;
    margin-top: 50px;
    justify-content: center;
    position: relative;
    transition: all 0.3s;
    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }
  //demo img
  .comparison-slider-wrapper {
    position: relative;
    width: 100%;
    background-color: transparent;
  }
  .comparison-slider-wrapper .comparison-slider {
    position: relative;
    width: 100%;
    margin: 0;
    // border: 5px white solid;
    box-sizing: border-box;
    &:hover {
      & .divider:before,
      & .divider:after,
      & .overlay.drag {
        display: block !important;
      }
    }
  }
  .comparison-slider-wrapper .comparison-slider > img {
    width: 100%;
    height: auto;
    display: block;
  }
  .comparison-slider-wrapper .comparison-slider .overlay {
    display: none;
    position: absolute;
    width: max-content;
    top: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    box-sizing: border-box;
    color: #ddd;
    text-align: center;
    border-radius: 10px;
    &.drag {
      display: none;
      top: calc(50% - 65px);
      right: 50%;
      transform: translateX(50%);
    }
  }
  @media (max-width: 500px) {
    .group-btn-decoHouse--theme {
      flex-direction: column;
    }
    .comparison-slider-wrapper {
      display: none;
    }
    .form-ai-house {
      width: 300px !important;
    }
    .group-btn-decoHouse--image {
      height: auto;
      align-items: center;
    }
    .group-btn-decoHouse {
      padding: 20px 0;
    }
    .group-btn-decoHouse--image .option {
      width: 80%;
    }
  }
  @media screen and (min-width: 767px) {
    .comparison-slider-wrapper .comparison-slider .overlay {
      display: block;
    }
  }
  .comparison-slider-wrapper .comparison-slider .resize {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
  .comparison-slider-wrapper .comparison-slider .resize > img {
    display: block;
    height: 100%;
    width: auto !important;
  }
  .comparison-slider-wrapper .comparison-slider .resize .overlay {
    right: auto;
    left: 20px;
    text-align: center;
  }
  .comparison-slider-wrapper .comparison-slider .divider {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: rgba(255, 134, 47, 0.507);
    left: 50%;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    cursor: ew-resize;
  }
  .comparison-slider-wrapper .comparison-slider .divider:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -9px;
    top: 50%;
    margin-top: -10px;
    background-color: var(--orange);
    transform: rotate(45deg);
    transition: all 0.1s ease-in-out;
    display: none;
  }
  .comparison-slider-wrapper .comparison-slider .divider:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: -5px;
    top: 50%;
    margin-top: -6px;
    background-color: var(--orange);
    transform: rotate(45deg);
    transition: all 0.1s ease-in-out;
    display: none;
  }
  .comparison-slider-wrapper .comparison-slider .divider.draggable:before {
    width: 30px;
    height: 30px;
    left: -14px;
    margin-top: -15px;
  }
  .comparison-slider-wrapper .comparison-slider .divider.draggable:after {
    width: 20px;
    height: 20px;
    left: -9px;
    margin-top: -10px;
    background-color: #fff;
  }
  .comparison-slider-wrapper .caption {
    position: relative;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-style: italic;
  }
  //.........
  .v-card--reveal {
    bottom: 0;
    left: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .choose-pic-group {
    flex-direction: column;
    button {
      height: auto !important;
      width: auto;
      padding: 0 !important;
    }
    .v-btn:before {
      background: transparent;
    }
    .v-btn.v-btn--active {
      box-shadow: var(--blue-text) 0px 0px 0px 3px;
    }
    @media (max-width: 500px) {
      flex-direction: row;
    }
  }
  .text-or {
    text-align: center;
    position: relative;
    &::after {
      content: '';
      width: 1px;
      height: 50px;
      background: #000;
      position: absolute;
      bottom: -60px;
      left: 50%;
    }
    &::before {
      content: '';
      width: 1px;
      height: 50px;
      background: #000;
      position: absolute;
      top: -60px;
      left: 50%;
    }
    @media (max-width: 500px) {
      text-align: center;
      &::after {
        content: '';
        width: 50px;
        height: 1px;
        background: #000;
        position: absolute;
        left: 60px;
        top: 50%;
        bottom: unset;
      }
      &::before {
        content: '';
        width: 50px;
        height: 1px;
        background: #000;
        position: absolute;
        left: -60px;
        top: 50%;
      }
    }
  }
  .title-result {
    font-weight: 700;
    color: var(--blue-text);
  }
  .btn-ai {
    border: none;
    font-size: 16px;
    font-weight: 700;
    height: 48px !important;
    width: 100% !important;
    text-align: center;
    background-color: var(--blue-text) !important;
    color: #fff;
    border-radius: 50px;
    &.btn-reset {
      border: 1px solid var(--blue-text) !important;
      background-color: #fff !important;
      color: var(--blue-text) !important;
    }
  }
  .btn-back {
    font-size: 16px;
    font-weight: 700;
    height: 48px !important;
    width: 150px !important;
    text-align: center;
    background-color: #dadada !important;
  }
  .contact-us__container {
    width: 80%;
    margin: auto;
    padding: 80px 0;
    padding-top: 80px;
  }
  .contact-us__content {
    padding: 8px 0;
    h2 {
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      z-index: 0;
      text-transform: none;
      text-align: center;
    }
    span {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
    }
    &.form {
      display: flex;
      justify-content: space-between;
      gap: 5%;
      .title-form {
        font-weight: 700;
        font-size: 40px;
        color: var(--blue-text);
      }
      img {
        border-radius: 10px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .contact-us__block {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    gap: 100px;
    flex-direction: column;
  }
  .contact-us__items {
    display: flex;
    //flex-direction: column;
    gap: 32px;
    width: 100%;
    img {
      height: 40px;
    }
  }
  .contact-us__item {
    flex: 1;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    color: var(--black-text);
    &:hover {
      text-decoration: none;
    }
  }
  .contact-us__item--content {
    h3 {
      font-weight: 700;
      font-size: 18px;
      color: var(--grey-text);
    }
    p {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
  }
  .contact-us__form {
    position: relative;
    width: 50%;
    min-width: 400px;
    //border: 2px solid var(--blue-text);
    border-radius: 10px;
    background-color: transparent;
    padding-top: 50px;
    &::after {
      content: '';
      position: absolute;
      top: -33%;
      right: -12%;
      width: 300px;
      height: 250px;
      background-image: url('/images/bg/penguin.png');
      background-size: cover;
      display: none;
    }
  }
  @media (max-width: 1024px) {
    & {
      height: unset;
    }
    .contact-us__block {
      flex-direction: column;
      gap: 60px;
    }
    .contact-us__items,
    .contact-us__form {
      flex-direction: column;
      width: 100%;
    }
    .contact-us__content {
      width: 100%;
      flex-direction: column;
      gap: 20px !important;
    }
  }
  @media (max-width: 768px) {
    .contact-us__container {
      padding-top: 100px;
    }

    & {
      padding-top: 0;
    }
    .contact-us__form {
      min-width: 0;
      padding: 10px;
      &::after {
        display: none;
      }
    }
    .contact-us__content {
      margin-top: 50px;
      h2 {
        font-size: 32px;
      }
      &.form {
        .title-form {
          font-size: 32px;
        }
      }
    }
  }
  .line {
    width: 100%;
    margin: 12px auto;
    height: 1px;
    background: #e3e3e3;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ai-container {
      justify-content: space-between;
      gap: 4px;
      padding: 0 12px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (max-width: 510px) {
      .ai-container {
        width: 100%;
        flex-direction: column;
      }
    }
  }
  .v-card__actions {
    justify-content: flex-end;
  }
  .form-ai-house {
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
      border: 1px solid #cccccc;
      border-radius: 50%;
    }
    .v-btn::before {
      display: none;
    }

    .v-btn-toggle > .v-btn.v-btn--active {
      background-color: #fff5ef !important;
      // font-weight: 800;
      color: var(--blue-text);
      border: 1px solid var(--blue-text) !important;
    }
    .group-options.v-btn-toggle:not(.v-btn-toggle--dense)
      .v-btn.v-btn.v-size--default {
      border-radius: 50px;
    }
    .choose-pic-group.v-btn-toggle:not(.v-btn-toggle--dense)
      .v-btn.v-btn.v-size--default {
      border-radius: 0px;
    }
    .v-btn-toggle:not(.v-btn-toggle--dense)
      .options-theme-item.v-btn.v-btn.v-size--default {
      border-radius: 0px;
    }
    .group-options {
      &.v-btn-toggle--group > .v-btn.v-btn {
        flex: 1;
        font-weight: 700;
      }
    }
  }
  .v-item-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .form-ai-house__label {
    margin-top: 12px;
  }
  .form-ai-house__group {
    padding: 0px 12px 4px 12px;
    i {
      font-weight: 400;
    }
    .input-data {
      margin-top: 10px;
      width: 100%;
      height: 40px;
      position: relative;
      flex: 1;
    }
    .input-data input,
    .textarea textarea {
      font-size: 16px;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      outline: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    }
    .input-data input:focus ~ label,
    .textarea textarea:focus ~ label,
    .input-data input:not(:placeholder-shown) ~ label,
    .textarea textarea:not(:placeholder-shown) ~ label {
      transform: translateY(-25px);
      font-size: 14px;
      color: var(--blue-text);
    }

    .textarea textarea {
      resize: none;
      padding-top: 10px;
    }

    .input-data label {
      position: absolute;
      pointer-events: none;
      bottom: 0px;
      background: #fff;
      transition: all 0.3s ease;
      font-size: 1.125rem;
      padding-right: 8px;
    }

    .textarea label {
      width: 100%;
      bottom: 170px;
      background: #fff;
    }

    .input-data .underline {
      position: absolute;
      bottom: 0px;
      height: 2px;
      width: 100%;
    }

    .input-data .underline:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      background: var(--blue-text);
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }

    .input-data input:focus ~ .underline:before,
    .textarea textarea:focus ~ .underline:before {
      transform: scale(1);
    }
  }
  .v-btn-toggle--group > .options-theme-item.v-btn.v-btn {
    margin: 0px;
  }
  .v-btn-toggle:not(.v-btn-toggle--dense)
    .options-theme-item.v-btn.v-btn.v-size--default {
    border: none;
  }
  .form-ai-house .v-btn-toggle > .options-theme-item.v-btn.v-btn--active {
    background-color: unset !important;
    border-color: transparent !important;
    color: unset !important;
    .options-theme-item__content {
      span {
        color: var(--blue-text) !important;
      }
      img {
        border: 2px solid var(--blue-text);
      }
    }
  }
  .options-theme-item {
    height: auto !important;
    .options-theme-item__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0;
      span {
        text-transform: initial !important;
      }
      img {
        width: 100px;
        border-radius: 50%;
        border: 2px solid #ccc;
        height: 100px;
        object-fit: cover;
      }
    }
  }
  .box {
    margin-top: 5px;
    display: block;
    width: 300px;
    max-width: 100%;
    height: 160px;
    background-color: white;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: 1px dashed #000;
    &:hover {
      label {
        visibility: visible !important;
      }
    }
  }

  .upload-options,
  .ai-options {
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background-color ease-in-out 150ms;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    height: max-content;
    background: transparent;
    & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    & label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: max-content;
      padding: 4px 12px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s;
      background-color: rgba(255, 255, 255, 0.85) !important;
      border-radius: 20px;
      border: 1px solid #cccc;
      & span {
        display: inline-block;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        color: #000;
        &:hover i.material-icons {
          color: lightgray;
        }
      }
    }
  }
  .ai-options {
    pointer-events: none;
    background-color: grey;
    & label::after {
      content: 'AI';
    }
  }

  .js--image-preview,
  .ai--image-preview {
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    object-fit: contain;
    background: #ffffff;
    &::after {
      position: relative;
      font-size: 4.5em;
      color: rgba(230, 230, 230, 1);
      top: calc(50% - 3rem);
      left: calc(50% - 2.25rem);
      z-index: 0;
    }
    &.js--no-default::after {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .js--image-preview,
    .ai--image-preview {
      height: 190px;
    }
    .box {
      height: 190px;
      width: 100%;
    }
  }
  i.material-icons {
    transition: color 100ms ease-in-out;
    font-size: 2.25em;
    line-height: 55px;
    color: white;
    display: block;
  }

  .animate {
    animation: ripple 0.4s linear;
  }

  @keyframes ripple {
    100% {
      opacity: 0;
      transform: scale(2.5);
    }
  }
  //Loader
  .loader {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    // background: rgb(0 0 0 / 60%);
    // background: #fafafa;
    background: transparent;
    border-radius: 10px;
    margin-top: 50px;
    padding: 2% 5%;

    .txt-queue {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 8px;
      text-align: center;
    }
    .loader-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-gap: 2px;
      width: 100px;
      height: 100px;
    }
    .loader-container > div {
      position: relative;
      width: 100%;
      height: 100%;
      background: tomato;
      transform: scale(0);
      transform-origin: center center;
      animation: loader 2s infinite linear;
    }
    .loader-container > div:nth-of-type(1),
    .loader-container > div:nth-of-type(5),
    .loader-container > div:nth-of-type(9) {
      animation-delay: 0.4s;
    }
    .loader-container > div:nth-of-type(4),
    .loader-container > div:nth-of-type(8) {
      animation-delay: 0.2s;
    }
    .loader-container > div:nth-of-type(2),
    .loader-container > div:nth-of-type(6) {
      animation-delay: 0.6s;
    }
    .loader-container > div:nth-of-type(3) {
      animation-delay: 0.8s;
    }
    @media (max-width: 768px) {
      .examper-wrapper {
        flex-direction: column;
      }
    }
  }

  .msg-err {
    font-size: 1.5rem;
    font-weight: 600;
    color: red;
    padding: 0px 12px 4px 12px;
  }

  @keyframes loader {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  .color-wrapper {
    position: relative;
    width: 250px;
    margin: 20px auto;
  }

  .color-wrapper p {
    margin-bottom: 5px;
  }

  input.call-picker {
    border: 1px solid #aaa;
    color: #666;
    text-transform: uppercase;
    float: left;
    outline: none;
    padding: 10px;
    text-transform: uppercase;
    width: 85px;
  }

  .color-picker {
    background: white;
    box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    span {
      padding: 4px 0px 0px 12px;
      font-weight: 500;
    }
  }

  .color-holder {
    background: #fff;
    cursor: pointer;
    border: 1px solid #aaa;
    width: 40px;
    height: 36px;
    float: left;
    margin-left: 5px;
  }

  .color-item {
    cursor: pointer;
    width: 10px;
    height: 10px;
    list-style-type: none;
    float: left;
    margin: 2px;
    border: 1px solid #ddd;
  }

  .color-item:hover {
    border: 1px solid #666;
    opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=8);
  }
  /////
  .input-color {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 6em;
    padding: 0;
    width: 6em;
  }
  .slider-number .v-slider__tick {
    width: 5px !important;
    height: 5px !important;
    left: calc(0% - 5px) !important;
    top: calc(50% - 5px) !important;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper-result {
    flex: 1;
  }
  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 80vw;
    max-height: 80vh;
    button {
      border: none;
      padding: 8px 12px;
      border-radius: 10px;
      background: #dadada;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
  }

  .btn-close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    width: fit-content;
  }

  .btn-close:hover,
  .btn-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 48px;
  }
  .container-result {
    position: relative;
    .overlay {
      top: 20px;
      left: 20px;
      position: absolute;
      border-radius: 10px;
      padding: 8px;
      color: #fff;
      background: black;
    }
    &:hover {
      .overlay-result {
        display: block;
        opacity: 1;
      }
    }
  }
  .overlay-result {
    display: none;
    opacity: 0;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
    left: 15px;
    top: 15px;
    background: #4040405e;
    transition: all 0.5s;
  }
  .btn-download {
    background-color: #dadada;
    color: #000;
    border: none;
    border-radius: 50%;
    height: 44px;
    width: 44px;

    transition: all 0.3s;
    &:hover {
      filter: brightness(80%);
    }
  }
}
/////
[data-tooltip] {
  --arrow-size: 5px;
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + var(--arrow-size));
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(
    30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44)
  );
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size); /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location='left']:before,
[data-tooltip-location='left']:after {
  left: auto;
  right: calc(100% + var(--arrow-size));
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location='left']:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
}
[data-tooltip-location='left']:hover:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
}

/* Arrow */
[data-tooltip-location='left']:after {
  border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location='left']:hover:after {
  transform: translateY(50%) scaleX(1);
}
</style>
